/***********************************
** Carousel
***********************************/
.swiper-button-next {
  background-image: url('../images/icons/arrow-right-icon.png');
}
.swiper-button-prev {
  background-image: url('../images/icons/arrow-left-icon.png');
}

.main-carousel {
  .swiper-slide {
    .carousel-bg {
      height: 370px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
    }

    .carousel-overlay {
      background: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
    }

    .carousel-info {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      left: 15%;
      padding: 0 40px;
      line-height: 3.5em;
      text-transform: uppercase;
      color: #fff;
      .carousel-line1 {
        font-size: 3.75em;
        font-weight: 300;
        display: block;
      }
      .carousel-line2 {
        font-size: 3em;
        font-weight: 900;
        display: block;
      }
    }
  }
}

.news-carousel, .articles-carousel {
  border-bottom: 1px solid $grey-line;
  .swiper-slide {
      .list-box {
        display: block;
        .list-image {
          position: relative;
          img {
            display: block;
          }
        }
        .title-3 {
          margin-top: 10px;
        }
        .list-text {

        }
        &:hover, &:focus {
          color: inherit;
        }
      }

  }
  .swiper-button-next, .swiper-button-prev {
    display: none;
  }
}

@media (min-width: 992px) {
  .main-carousel {
    .swiper-button-next {
      right: 40px;
    }
    .swiper-button-prev {
      left: 40px;
    }
  }
}

@media (max-width: 991px) {
  .main-carousel {
    .swiper-slide {
      .carousel-bg {
        height: 250px;
      }
      .carousel-info {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        @include transform(translateX(-50%) translateY(-50%));

        text-align: center;
        .carousel-line1 {
          font-size: 3em;
        }
        .carousel-line2 {
          font-size: 2.25em;
        }
      }
    }
  }
}

@media(max-width: 600px) {
  .main-carousel {
    .swiper-slide {
      .carousel-info {
        .carousel-line1 {
          font-size: 2.5em;
        }
        .carousel-line2 {
          font-size: 1.75em;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .news-carousel, .articles-carousel {
    .swiper-slide {
      .list-box {
        .list-image {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,0.5);
          }
        }
      }
    }
    .swiper-button-next, .swiper-button-prev {
      display: block;
      top: 40%;
    }
  }

}
