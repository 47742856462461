/***********************************
** Sidebar
***********************************/

.sidebar {
  padding: 10px 0;
  max-width: 768px;
  margin:0 auto;
  .banners {
    text-align: center;
    margin-top: 40px;
    .banner-box {
      overflow: hidden;
      width: 300px;
      text-align: center;
      margin: 0 auto;
      display: inline-block;
      vertical-align: baseline;
    }
  }
  .section-title {
    text-transform: inherit;
    font-size: 1em;
    font-weight: 700;
    .title-text {
      span {
        font-weight: 700;
      }
    }
  }
}

.related-wrapper {
  ul {
    padding-left: 20px;
    color: $orange-main;
    list-style-type: square;
   > li {
      > a {
        cursor: pointer;
      }
    }
  }
}
@media(max-width: 1199px) {
  .sidebar {
    .banners {
      .banner-box {
        vertical-align: top;
      }
    }
  }
}
@media(max-width: 991px) {
  .sidebar {
    .section-title {
      font-size: 14px;
    }
  }
  .related-wrapper {
    font-size: 17px;
  }
}
