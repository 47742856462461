/***********************************
** Banner
***********************************/

.banner-wrapper {
  position: relative;
  display: block;
  img {
    display: block;
  }
  .banner-title {
    position: absolute;
    top: 50%;
    left: 5%;
    @include transform(translateY(-50%));
  }
}

.main-banner-wrapper {
  height: 150px;
  .banner-bg {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 150px;
    line-height: 150px;
  }
  .banner-top-title {
    font-size: 2.5em;
    display: inline-block;
    width: 100%;
    padding:0 15px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: 300;
    vertical-align: middle;
    span {
      font-weight: 900;
    }
  }
}





@media(min-width: 768px) {
  .layout-3 {
    .banner-wrapper {
      &.bg {
        font-size: 14px;
        height: 120px;
        background-position: 30% center;
      }
    }
  }
  .layout-2 {
    .banner-wrapper {
      &.bg {
        font-size: 16px;
        height: 120px;
        background-position: 30% center;
      }
    }
  }
}

@media(min-width: 992px) {
  .layout-3 {
    .banner-wrapper {
      &.bg {
        font-size: 12px;
      }
    }
  }
  .layout-4 {
    .banner-wrapper {
      &.bg {
        font-size: 10px;
        height: 80px;
        background-position: 30% center;
      }
    }
  }
  .layout-2 {
    .banner-wrapper {
      &.bg {
        font-size: 12px;
        height: 100px;
      }
    }
  }
}

@media(min-width: 1200px) {
  .layout-3 {
    .banner-wrapper {
      &.bg {
        font-size: 10px;
        height: 80px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
      }
    }
  }
  .layout-2 {
    .banner-wrapper {
      &.bg {
        font-size: 14px;
        height: 120px;
      }
    }
  }
}

@media(min-width: 1450px) {
  .layout-4 {
    .banner-wrapper {
      &.bg {
        font-size: 12px;
      }
    }
  }
  .layout-2 {
    .banner-wrapper {
      &.bg {
        font-size: 16px;
        height: 150px;
      }
    }
  }
}


@media (max-width: 1199px) {
  .banner-wrapper {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .layout-4 {
    .banner-wrapper {
      &.bg {
        font-size: 12px;
        height: 120px;
        background-position: 30% center;
      }
    }
  }
}

@media (max-width: 767px) {
  .layout-3 {
    .banner-wrapper {
      font-size: 12px;
      &.bg {
        height: 120px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
      }
    }
  }
  .layout-2 {
    .banner-wrapper {
      &.bg {
        font-size: 12px;
        height: 120px;
      }
    }
  }
  .casino-list-view {
    .main-banner-wrapper {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .banner-wrapper {
    font-size: 10px;
  }

  .layout-3 {
    .banner-wrapper {
      &.bg {
        font-size: 10px;
        height: 90px;
      }
    }
  }

  .layout-4 {
    .banner-wrapper {
      &.bg {
        font-size: 10px;
        height: 120px;
      }
    }
  }
  .layout-2 {
    .banner-wrapper {
      &.bg {
        font-size: 10px;
        height: 100px;
      }
    }
  }
}
