/***********************************
** Main
***********************************/

// Global
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
}

a {
  color: black;
}

a:hover, a:focus {
  color: $orange-main;
  text-decoration: none;
}

em {
  font-style: italic;
}

.table {
  display: table;
}

.cell {
  display: table-cell;
}

.title-3 { font-weight: 600; font-size: 1.0625em; text-transform: uppercase;}

.mb-1 { margin-bottom: 10px; }
.mb-2 { margin-bottom: 20px; }
.mb-3 { margin-bottom: 30px; }
.mb-4 { margin-bottom: 40px; }
.mb-5 { margin-bottom: 50px; }

.mt-1 { margin-top: 10px; }
.mt-2 { margin-top: 20px; }
.mt-3 { margin-top: 30px; }
.mt-4 { margin-top: 40px; }
.mt-5 { margin-top: 50px; }

.pb-1 { padding-bottom: 10px; }
.pb-2 { padding-bottom: 20px; }
.pb-3 { padding-bottom: 30px; }
.pb-4 { padding-bottom: 40px; }
.pb-5 { padding-bottom: 50px; }

.pt-1 { padding-top: 10px; }
.pt-2 { padding-top: 20px; }
.pt-3 { padding-top: 30px; }
.pt-4 { padding-top: 40px; }
.pt-5 { padding-top: 50px; }

.align-center {
  text-align: center;
}

.container-big {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media(min-width: 768px) {
  .container-big {
    width: 750px;
  }
}

@media(min-width: 992px) {
  .container-big {
    width: 970px;
  }
}

@media(min-width: 1200px) {
  .container-big {
    padding-left: 40px;
    padding-right: 40px;
    width: auto;

    [class*="col-"] {
      padding-left: 20px;
      padding-right: 20px;
    }
    .row {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media(min-width: 1450px) {
  .container {
    width: 1300px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .main-sidebar-view {
    > .col-lg-3 {
      width: 28%;
      right: 72%;
    }

    > .col-lg-9 {
      width: 72%;
      left: 28%;
    }
  }

  .casino-grid {
    &.col-lg-3 {
      width: 28%;
    }
    &.col-lg-9 {
      width: 72%;
    }
  }
}

@media(max-width: 1199px) {
  body {
    font-size: 14px;
  }
}

@media(max-width: 991px) {
  .container {
    > [class*="col-"] {
      padding: 0;
    }
  }
}

@media(max-width: 480px) {
  body {
    font-size: 12px;
  }
}
// Headings

.section-title {
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 2px solid $orange-main;
  padding: 12px 0px;
  .title-text {
    font-size: 1.5em;
    position: relative;
    > span {
      font-weight: 900;
    }
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0px;
      bottom: -12px;
      height: 5px;
      background: $orange-main;
      @include transition(all 0.3s ease);
    }
  }
  > a {
    font-size: 1em;
    float: right;
    margin-top: 0.5em;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.banner-title {
  font-size: 1.875em;
  text-transform: uppercase;
  font-weight: 300;
  color: white;
  span {
    font-weight: 900;
    color: $orange-main;
  }
}

// Buttons

.btn {
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  @include border-radius(0);
  @include transition (all .1s ease);
  font-size: 1em;
  &:focus, &:active {
    outline: 0;
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    text-decoration: none;
  }
}
.btn-table {
  @include background-gradient(top right, $orange-button2, $orange-button1);
  &:hover {
    color: white;
  }
  &.btn-grey {
    background: $grey-button1;
    color: white;
  }
}

.btn-hidden-desktop {
  .btn {
    font-size: 2.875em;
    font-weight: 300;
  }
}

.pagination-wrapper {
  width: 100%;
  text-align: center;
  font-size: 20px;

  .pagination {
    &:after, &:before {
      content:'';
      display: table;
      clear: both;
    }
    > li {
      > * {
        color: #ababab;
        border: none;

      }

      a {
        border: none;
        color: #ababab;
        &:hover, &:active, &:focus {
          background: inherit;
          color: $orange-main;
        }
      }
      .current {
        color: #000;
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
