
.main-banner-wrapper .banner-bg {
  &.fixed {
    position: fixed;
    top: -100px;
    left: 0;
    height: 80px;
    width: 100%;
    z-index: 10;
    &.animated {
      @include transition(top .25s ease);
      top: 0px;
    }
  }
}
.casino-wrapper .casino-info .top {
  &.fixed {
    position: fixed;
    left: 140px;
    top: -100px;
    z-index: 11;
    &.animated {
      @include transition(top .25s ease);
      top: 21px;
    }
  }
}
.casino-wrapper .casino-info .features-button {
  &.fixed {
    position: fixed;
    right: 45px;
    top: -100px;
    width: 200px;
    z-index: 11;
    display: block;
    height: 60px;
    overflow: hidden;
    @include transition(top .25s ease);
    &.animated {
      top: 10px;
    }
  }
}

.casino-wrapper .casino-img img {
  &.fixed {
    position: fixed;
    top: -100px;
    left: 45px;
    z-index: 11;
    width: auto;
    height: 60px;
    @include transition(top .25s ease);
    &.animated {
      top: 10px;
    }
  }
}

//back to top scroll
#back-to-top {
    position: fixed;
    bottom: -100px;
    right: 45px;
    z-index: 9999;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 35px;
    font-size: 18px;
    background: $red-main;
    color: #fff;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    @include transition(bottom 0.5s ease-out);
}
#back-to-top:hover {
    background: $red-second;
}
#back-to-top.show {
    bottom: 45px;
}

@media (max-width: 767px) {
  #back-to-top {
    right: 0;
  }
  #back-to-top.show {
      bottom: 0px;
  }
}
