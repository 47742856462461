/***********************************
** Footer
***********************************/

.tooltipster-wrapper {
  position: absolute;
  width: 220px;
  padding: 10px;
  bottom: calc(100% + 10px);
  left: 50%;
  @include transform(translateX(-50%));
  @include transition(all .5s ease-in);
  text-align: center;
  background: #222;
  color: #fff;
  opacity: 0;
  border: 2px solid orange;
  @include border-radius(5px);
  &:before,&:after {
    content: '';
    position: absolute;
    left: 50%;
    @include transform(translateX(-50%));
    bottom: -12px;
    width: 8px;
    height: 10px;
    z-index: 99;
    background: transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid orange;
    border-right: 8px solid transparent;
  }
  &:after {
    bottom: -10px;
    border-top: 8px solid #222;
  }
}

.pre-footer {
  text-align: center;
  border-top: 1px solid $orange-main;
  border-bottom: 1px solid $orange-main;
  background: #fff;
  .inline-logo {
    line-height: 100px;
    display: inline-block;
    padding: 15px;
    text-align: left;
    font-size: 12px;
    a {
      line-height: 100px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      &:hover {
        .tooltipster-wrapper {
          opacity: 1;
        }
      }
      p {
        line-height: normal;
        max-width: 200px;
        text-align: center;
      }
    }
  }
}

.footer-wrapper {
  text-align: center;
  background: $grey-bg;
  .footer-nav {

    font-size: 17px;
    ul {
      list-style: none;
      padding: 20px 0 15px;
      display: inline-block;
      border-bottom: 1px solid $grey-line;
      li {
        display: inline;
        > a {
          padding: 0 18px;
        }
      }
      &:before,&:after {
        display: table;
        content: " ";
      }
    }
  }
  .copyright-wrapper {
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 20px;
  }
}

@media(max-width: 991px) {
  .pre-footer {
    .inline-logo {
      display: block;
      padding: 20px 15px;
      text-align: center;
      line-height: normal;
      a {
        line-height: normal;
      }
    }
  }
  .footer-wrapper {
    .footer-nav {
      font-size: 14px;
      ul {
        li {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
}
