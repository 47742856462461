/***********************************
** Settings
***********************************/

// Colors

  $orange-main: #ff7500;

  $orange-button1: #ffa800;
  $orange-button2: #ff7500;

  $grey-button1: #cacaca;
  $grey-bg: #e5e5e5;
  $grey-bg2: #ededed;
  $grey-line: #c5bfbf;

  $red-main: #ce3939;
  $red-second: #a22b2b;
