/***********************************
** Article
***********************************/

.article-wrapper {
  padding: 10px 0;
  text-align: justify;
  img {
    padding: 10px;
  }
  p {
    padding: 5px 0;
    font-size: 1.25em;
  }
  h1 { font-size: 2.25em }
  h2 { font-size: 1.75em }
  h3 { font-size: 1.37em }
  h4 { font-size: 1.17em }
  h5 { font-size: 1.03em }
  h6 { font-size: 0.87em }

  .alignright {
    float: right;
    padding: 10px 0 10px 15px;
  }
  .aligncenter {
    margin: 0 auto;
    display: block;
  }
  .alignleft {
    float: left;
    padding: 10px 15px 10px 0;
  }

  ul {
    padding-left: 40px;
  }
  a {
    color: $orange-main;
  }
}



.news-item {
  &.row {
    margin-left: 0;
    margin-right: 0;
  }
  width: 100%;
  .column-left {
    width: 25%;
    float: left;
  }
  .column-right {
    width: 75%;
    float: left;
  }
  .news-img {
    height: 180px;
    display: block;
    overflow: hidden;
    position: relative;
    img {
      display: block;
      position: absolute;
      max-width: none;
      top: 50%;
      left: 50%;
      @include transform(translateY(-50%) translateX(-50%));
      max-width: 450px;
    }
  }
  .news-info {
    padding: 10px 20px;
    position: relative;
    height: 180px;
    h2 {
      font-size: 1.875em;
      font-weight: 700;
    }
    p {
      height: 100%;
      font-size: 1.25em;
      height: 100px;
      margin-top: 15px;
    }
    .read-more {
      position: absolute;
      bottom: 10px;
      a {
        font-size: 1.375em;
        color: $orange-main;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 1499px) {
  .news-item {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .news-item {
    font-size: 13px;
    .column-left {
      width: 30%;
    }
    .column-right {
      width: 70%;
    }
  }
}

@media (max-width: 991px) {
  .news-item {
    font-size: 11px;
    .column-left {
      width: 35%;
    }
    .column-right {
      width: 65%;
    }
    .news-img {
      max-width: 350px;
    }
    .news-info {
      p {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .news-item {
    font-size: 10px;
    padding: 10px;
    margin-bottom: 0;
    .column-left {
      width: 40%;
    }
    .column-right {
      width: 60%;
    }

    .news-img {
      height: 140px;
      max-width: 300px;
    }

    .news-info {
      height: 140px;
      h2 {
        margin-bottom: 20px;
      }
      p {
        display: none;
      }
      .read-more {
        position: relative;
      }
    }
  }
}

@media (max-width: 480px) {
  .news-item {
    .column-left {
      width: 50%;
    }
    .column-right {
      width: 50%;
    }
  }
}
