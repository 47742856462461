/***********************************
** Boxes
***********************************/

// Front table view

.col_rank {
  font-size: 0;
  width: 165px;
  padding-right: 15px;
}

.icon-star {
  background: url('../images/icons/star-icon.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  display: inline-block;
  height: 22px;
  width: 22px;
  margin: 3px;
}

.icon-apple, .icon-android {
  background: url('../images/icons/apple-icon.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  margin:2px 3px 2px 0;
  height: 17px;
  width: 14px;
}
.icon-android {
  background: url('../images/icons/android-icon.png');
}

.icon-yes, .icon-no {
  background: url('../images/icons/yes-icon.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  margin: -2px 0px 2px 3px;
  height: 17px;
  width: 17px;
}

.list-table {
  .icon-yes, .icon-no {
    height: 15px;
    width: 15px;
  }
}

.icon-no {
  background-image: url('../images/icons/no-icon.png');
}

.col_icons {
  width: 50px;
  text-align: right;
}
// Features list

.features-list {
  &.wtp {
    width: 70%;
    > div {
      vertical-align: top;
      margin-top: 10px;
    }
    .wtp-box {
      display: inline-block;
      vertical-align: top;
      width: 80%;
      padding-left: 15px;
    }
    a {
      text-decoration: underline;
    }
  }

  > div {
    font-size: 0.9375em;
    font-weight: 400;
    text-align: left;
    > span {
      font-weight: 700;
      color: $orange-main;
    }
  }
}

.table-box-wrapper {
  .table {
    display: table;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey-line;
    .cell {
      display: table-cell;
      vertical-align: middle;
      border-top: none;
      padding: 0 3px;
      text-align: center;
      font-weight: inherit;
    }
    img {
      vertical-align: middle;
    }
    .col_payout {
      font-size: 1em;
      font-weight: 400;
      text-align: right;
      padding-right: 20px;
      strong {
        color: $orange-main;
      }
    }
  }
  .col-img {
    img {
      max-width: 80px;
    }
  }

    .col_button {
        text-align: right;
        width: 130px;
        font-size: 1.25em;
        .btn {
          padding: 3px 0px;
          margin: 3px 0;
        }
    }


    .col_img {
      min-width: 70px;
      text-align: left;
      width: 10%;
    }

    .col_lp {
      width: 3%;
    }

    .col_title {
      text-align: left !important;
      padding-left: 1%;
      padding-right: 1%;
      font-size: 1.25em;
    }

    .btn-table {
      width: 100%;
    }

    .table-inside {
      display: table;
      > * {
        display: table-cell;
        vertical-align: middle;
        padding: 0 5px;
      }
    }
}

// List table

.table-box-wrapper.list-table {
  .col-img {
    img {
      max-width: 115px;
    }
  }
  .col_rank {
    display: block;
    padding-top: 5px;
  }
  .icon-star {

  }
  .table-inside {
    > * {
      display: block;
    }
  }

  .col_lp {
    font-size: 1.25em;
  }

  .col_title {
    font-size: 1.625em;
  }

  .col_button {
    width: 150px;
  }

  .col_features {
    width: 150px;
    &.xl {
      width: 200px;
    }
  }
  &.slot {
    .col_features {
      &.column-1 {
        width: 135px;
      }
      &.column-2 {
        width: 175px;
      }
    }
  }
}

// Casino

.casino-wrapper {
  margin-top: -120px;

  .casino-img {
    height: 240px;
    img {
      width: 100%;
      display: block;
      height: 240px;
    }
  }
  .casino-info {
    padding:25px 0;
    position: relative;
    &.slot {

      .btn-table {
        top: 0;
      }
      .bottom {
        top: auto;
        position: relative;
        margin-top: 60px;
      }
    }
    .features-button {
      position: absolute;
      right:0;
      top: 0;
      width: 28%;
      .btn {
        width: 100%;
        right: 0;
        padding-left: 0;
        padding-right: 0;
        position: relative;
      }
      a {
        display: block;
        img {
          width: 100%;
        }
      }
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .top {
    }
    .bottom {
      position: absolute;
      top: 120px;
      width: 100%;
    }
    .title {
      color: #fff;
      font-size: 2em;
      display: inline-block;
      vertical-align: middle;
    }
    .col_rank {
      margin-left: 1.5em;
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
    }
  }
  .features-list {
    display: inline-block;
    vertical-align: top;
    font-size: 1.125em;
    padding-right: 15px;
  }
  .btn-table {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    padding: 8px 75px;
    font-size: 2em;

    position: absolute;
    right: 10px;
  }
}

//TOP 5

.top5-rank {
  font-size: 14px;
  .table {
    margin-bottom: 5px;
  }
  .cell {
    vertical-align: middle;
    padding: 4px;
  }
  .col_lp {
    width: 10px;
  }
  .col_title {
    font-weight: 600;
  }
  .col_img {
    width: 26%;
    a {
      display: block;
      img {
        display: block;
      }
    }
  }
  .col_button {
    width: 85px;
    .btn {
      padding: 5px 12px;
      margin-bottom: 3px;
    }
  }

}

@media(min-width: 768px) {
  .icon-star {
    &.number {
      display: none;
    }
  }
}

@media(max-width: 1679px) {
  .table-box-wrapper {
    font-size: 14px;
    &.list-table {
      font-size: 16px;
    }
    .col_button {
      width: 110px;
    }
  }
  .icon-star {
    height: 18px;
    width: 18px;
  }
  .col_rank {
    width: 140px;
    padding-right: 10px;
  }
}

@media(max-width: 1449px) {
  .table-box-wrapper {
    font-size: 11px;
    .col_button {
      width: 90px;
    }
  }
  .icon-star {
    height: 16px;
    width: 16px;
  }

  .col_rank {
    font-size: 0;
    width: 130px;
    padding-right: 10px;
  }

  .casino-wrapper {
    .casino-img {
      height: 210px;
      img {
        height: 210px;
      }
    }
  }
  .features-list {
    &.wtp {
      width: 70%;
    }
  }
}

@media(max-width: 1199px) {
  .table-box-wrapper {
    font-size: 16px;
    .col_button {
      width: 130px;
    }
  }

  .icon-star {
    height: 22px;
    width: 22px;
  }
  .col_rank {
    width: 155px;
    padding-right: 10px;
  }

  .casino-wrapper {
    .casino-img {
      height: 230px;
      img {
        height: 230px;
      }
    }
    .casino-info {
      padding: 35px 0;
      &.slot {
        .features-button {
          width: 30%;
        }
      }
    }
  }
  .features-list {
    &.wtp {
      width: 75%;
      .wtp-box {
        margin-top: 10px;
        width: 100%;
        padding-left: 0;
      }
    }
    &.dev {
      width: 100%;
    }
  }

  .top5-rank {
    font-size: 20px;
    .col_img {
      width: 150px;
    }
    .col_button {
      width: 250px;
      .btn {
        padding: 5px 12px;
        margin-bottom: 10px;
      }
    }
    .col_title {
      padding-left: 25px;
    }
  }
}

@media(max-width: 991px) {
  .table-box-wrapper {
    font-size: 12px;
    &.list-table {
      font-size: 14px;
      .col_features {
        width: 120px;
      }
      .col_title {
        font-size: 1.25em;
      }
      .col_button {
        width: 125px;
      }
      &.slot {
        .col_features {
          &.column-1 {
            width: 125px;
          }
          &.column-2 {
            width: 170px;
          }
        }
      }
    }

  }
  .col_button {
    width: 110px;
  }
  .icon-star {
    height: 18px;
    width: 18px;
  }
  .col_rank {
    padding-right: 0px;
  }

  .casino-wrapper {
    font-size: 14px;
    .casino-img {
      height: 200px;
      img {
        height: 200px;
      }
    }
    .casino-info {
      padding: 20px 0;
      .btn {
        padding: 8px 40px;
      }
    }
  }
}

@media(max-width: 767px) {
  .page-template-template-rank-item {
    padding-bottom: 68px;
  }

  .table-box-wrapper {
    font-size: 14px;
    .table {
      .col_rev {
        display: none;
      }
    }
    &.list-table {


      .col_title {
        font-size: 1.25em;
      }
      .col_button {
        width: 130px;
      }
      .col_img {
        width: 14%;
        img {
        }
      }
      .col_features {
        display: none;
      }
      .col_rank {
        width: 130px;
      }
      .icon-star {
        display: inline-block;
        &.number {
          display: none;
        }
      }
      .col_rev {
        display: block;
      }
    }
    .col_button {
      width: 110px;
    }
  }
  .icon-star {
    display: none;
    &.number {
      display: inline-block;
      font-size: 16px;
      width: 32px;
      height: 20px;
      background-size: 18px 18px;
      background-position: right;
      color: orange;
      text-align: left;
      font-weight: 900;
    }
  }
  .col_rank {
    width: 50px;
  }

  .casino-wrapper {
    font-size: 14px;
    margin-top: 20px;
    .casino-info {
      &.slot {
        .bottom {
          margin-top: 15px;
        }
      }
    }
    .casino-grid {
      &.col-xs-4 {
        width: 35%;
        min-width: 250px;
      }

      &.col-xs-8 {
        width: 55%;
      }
    }

    .casino-img {
      img {
        height: auto;
      }
    }
    .casino-info {
      padding: 20px 0;
      .title {
        margin-bottom: 5px;
        display: block;
        color: #000;
      }
      .bottom {
        margin-top: 20px;
        position: static;
      }
      .features-button {
        .btn {
          position: fixed !important;
          width: 100% !important;
          left: 0;
          top: auto;
          bottom: 0;
          z-index: 10;
          font-size: 2.875em;
          font-weight: 300;
        }
      }
      .col_rank {
        width: 130px;
        display:block;
        margin-left: 0;
      }
      .icon-star {
        display: inline-block;
        &.number {
          display: none;
        }
      }
    }
    .features-list {
      &.wtp {
        width: 100%;
      }
    }
  }

  .top5-rank {
    font-size: 16px;
    .col_img {
      width: 120px;
      }
    .col_button {
      width: 200px;
      .btn {
        padding: 5px 12px;
        margin-bottom: 10px;
      }
    }

  }
}

@media(max-width: 600px) {
  .table-box-wrapper {
    font-size: 12px;
    &.list-table {
      font-size: 12px;
    }
  }

  .casino-wrapper {
    font-size: 12px;
    .casino-grid {
      &.col-xs-4 {
        width: 24%;
        min-width: 190px;
      }
    }
    .casino-info {
      padding: 0;
    }
  }

  .top5-rank {
    font-size: 16px;
    .col_img {
      width: 105px;
      }
    .col_button {
      width: 140px;
      .btn {
        padding: 5px 12px;
        margin-bottom: 5px;
      }
    }
    .col_title {
      padding-left: 5px;
    }

  }
}

@media(max-width: 480px) {
  .table-box-wrapper {
    font-size: 11px;
    &.list-table {
      font-size: 11px;
      .col_button {
        width: 70px;
      }
      .col_rank {
        width: auto;
      }
    }
    .col_button {
      width: 70px;
    }
    .col_rev {
      display: none;
    }
    .table {
      .col_payout {
        display: none;
      }
      .icon-star {
        display: none;
        &.number {
          display: block;
        }
      }
      .table-inside {
        span {
          display: none;
        }
      }
    }
  }
  .casino-wrapper {
    font-size: 14px;
    text-align: center;
    .casino-img {
      height: auto;
    }
    .casino-grid {
      width: 100% !important;
    }
    .casino-info {
      height: auto;
      padding: 0;
      .title {
        margin-top: 15px;
      }
      .col_rank {
        display: inline-block;
      }
      .bottom {
        margin-top: 0;
      }
      .features-list {
        padding-right: 0;
        width: 100%;
        > div {
          text-align: center;

        }
        &:first-child {
          padding: 8px;
        }
      }
    }
  }
  .top5-rank {
    font-size: 14px;
    .col_img {
      width: 70px;
      }
    .col_button {
      width: 25%;
      .btn {
        padding: 5px 12px;
        margin-bottom: 3px;
      }
    }

  }
}
