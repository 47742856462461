/***********************************
** Settings
***********************************/
html {
  margin: 0;
  padding: 0;
  border: 0; }

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline; }

article, aside, dialog, figure, footer, header, hgroup, nav, section {
  display: block; }

body {
  line-height: 1.5;
  background: white; }

table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  float: none !important; }

table, th, td {
  vertical-align: middle; }

blockquote before, blockquote after, q before, q after {
  content: ''; }

a img {
  border: none; }

b, i {
  display: none; }

/***********************************
** Main
***********************************/
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px; }

img {
  height: auto;
  max-width: 100%; }

a {
  color: black; }

a:hover, a:focus {
  color: #ff7500;
  text-decoration: none; }

em {
  font-style: italic; }

.table {
  display: table; }

.cell {
  display: table-cell; }

.title-3 {
  font-weight: 600;
  font-size: 1.0625em;
  text-transform: uppercase; }

.mb-1 {
  margin-bottom: 10px; }

.mb-2 {
  margin-bottom: 20px; }

.mb-3 {
  margin-bottom: 30px; }

.mb-4 {
  margin-bottom: 40px; }

.mb-5 {
  margin-bottom: 50px; }

.mt-1 {
  margin-top: 10px; }

.mt-2 {
  margin-top: 20px; }

.mt-3 {
  margin-top: 30px; }

.mt-4 {
  margin-top: 40px; }

.mt-5 {
  margin-top: 50px; }

.pb-1 {
  padding-bottom: 10px; }

.pb-2 {
  padding-bottom: 20px; }

.pb-3 {
  padding-bottom: 30px; }

.pb-4 {
  padding-bottom: 40px; }

.pb-5 {
  padding-bottom: 50px; }

.pt-1 {
  padding-top: 10px; }

.pt-2 {
  padding-top: 20px; }

.pt-3 {
  padding-top: 30px; }

.pt-4 {
  padding-top: 40px; }

.pt-5 {
  padding-top: 50px; }

.align-center {
  text-align: center; }

.container-big {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 768px) {
  .container-big {
    width: 750px; } }

@media (min-width: 992px) {
  .container-big {
    width: 970px; } }

@media (min-width: 1200px) {
  .container-big {
    padding-left: 40px;
    padding-right: 40px;
    width: auto; }
    .container-big [class*="col-"] {
      padding-left: 20px;
      padding-right: 20px; }
    .container-big .row {
      margin-left: -20px;
      margin-right: -20px; }
  [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }
  .row {
    margin-left: -10px;
    margin-right: -10px; } }

@media (min-width: 1450px) {
  .container {
    width: 1300px; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .main-sidebar-view > .col-lg-3 {
    width: 28%;
    right: 72%; }
  .main-sidebar-view > .col-lg-9 {
    width: 72%;
    left: 28%; }
  .casino-grid.col-lg-3 {
    width: 28%; }
  .casino-grid.col-lg-9 {
    width: 72%; } }

@media (max-width: 1199px) {
  body {
    font-size: 14px; } }

@media (max-width: 991px) {
  .container > [class*="col-"] {
    padding: 0; } }

@media (max-width: 480px) {
  body {
    font-size: 12px; } }

.section-title {
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 2px solid #ff7500;
  padding: 12px 0px; }
  .section-title .title-text {
    font-size: 1.5em;
    position: relative; }
    .section-title .title-text > span {
      font-weight: 900; }
    .section-title .title-text:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0px;
      bottom: -12px;
      height: 5px;
      background: #ff7500;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
  .section-title > a {
    font-size: 1em;
    float: right;
    margin-top: 0.5em;
    font-weight: 300;
    text-transform: uppercase; }

.banner-title {
  font-size: 1.875em;
  text-transform: uppercase;
  font-weight: 300;
  color: white; }
  .banner-title span {
    font-weight: 900;
    color: #ff7500; }

.btn {
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  font-size: 1em; }
  .btn:focus, .btn:active {
    outline: 0;
    text-decoration: none;
    color: inherit; }
  .btn:hover {
    text-decoration: none; }

.btn-table {
  background-color: #ff7500;
  background: -webkit-linear-gradient(left bottom, #ff7500, #ffa800);
  /* Safari 5.1 to 6.0 */
  background: -o-linear-gradient(top right, #ff7500, #ffa800);
  /* Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(top right, #ff7500, #ffa800);
  /* Firefox 3.6 to 15 */
  background: linear-gradient(to top right, #ff7500, #ffa800);
  /* Standard syntax */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#ff7500', endColorStr='#ffa800'); }
  .btn-table:hover {
    color: white; }
  .btn-table.btn-grey {
    background: #cacaca;
    color: white; }

.btn-hidden-desktop .btn {
  font-size: 2.875em;
  font-weight: 300; }

.pagination-wrapper {
  width: 100%;
  text-align: center;
  font-size: 20px; }
  .pagination-wrapper .pagination:after, .pagination-wrapper .pagination:before {
    content: '';
    display: table;
    clear: both; }
  .pagination-wrapper .pagination > li > * {
    color: #ababab;
    border: none; }
  .pagination-wrapper .pagination > li a {
    border: none;
    color: #ababab; }
    .pagination-wrapper .pagination > li a:hover, .pagination-wrapper .pagination > li a:active, .pagination-wrapper .pagination > li a:focus {
      background: inherit;
      color: #ff7500; }
  .pagination-wrapper .pagination > li .current {
    color: #000; }
    .pagination-wrapper .pagination > li .current:hover {
      background: transparent; }

/***********************************
** Article
***********************************/
.article-wrapper {
  padding: 10px 0;
  text-align: justify; }
  .article-wrapper img {
    padding: 10px; }
  .article-wrapper p {
    padding: 5px 0;
    font-size: 1.25em; }
  .article-wrapper h1 {
    font-size: 2.25em; }
  .article-wrapper h2 {
    font-size: 1.75em; }
  .article-wrapper h3 {
    font-size: 1.37em; }
  .article-wrapper h4 {
    font-size: 1.17em; }
  .article-wrapper h5 {
    font-size: 1.03em; }
  .article-wrapper h6 {
    font-size: 0.87em; }
  .article-wrapper .alignright {
    float: right;
    padding: 10px 0 10px 15px; }
  .article-wrapper .aligncenter {
    margin: 0 auto;
    display: block; }
  .article-wrapper .alignleft {
    float: left;
    padding: 10px 15px 10px 0; }
  .article-wrapper ul {
    padding-left: 40px; }
  .article-wrapper a {
    color: #ff7500; }

.news-item {
  width: 100%; }
  .news-item.row {
    margin-left: 0;
    margin-right: 0; }
  .news-item .column-left {
    width: 25%;
    float: left; }
  .news-item .column-right {
    width: 75%;
    float: left; }
  .news-item .news-img {
    height: 180px;
    display: block;
    overflow: hidden;
    position: relative; }
    .news-item .news-img img {
      display: block;
      position: absolute;
      max-width: none;
      top: 50%;
      left: 50%;
      -webkit-transform: translateY(-50%) translateX(-50%);
      -moz-transform: translateY(-50%) translateX(-50%);
      transform: translateY(-50%) translateX(-50%);
      max-width: 450px; }
  .news-item .news-info {
    padding: 10px 20px;
    position: relative;
    height: 180px; }
    .news-item .news-info h2 {
      font-size: 1.875em;
      font-weight: 700; }
    .news-item .news-info p {
      height: 100%;
      font-size: 1.25em;
      height: 100px;
      margin-top: 15px; }
    .news-item .news-info .read-more {
      position: absolute;
      bottom: 10px; }
      .news-item .news-info .read-more a {
        font-size: 1.375em;
        color: #ff7500;
        font-weight: 700; }

@media (max-width: 1499px) {
  .news-item {
    font-size: 14px; } }

@media (max-width: 1200px) {
  .news-item {
    font-size: 13px; }
    .news-item .column-left {
      width: 30%; }
    .news-item .column-right {
      width: 70%; } }

@media (max-width: 991px) {
  .news-item {
    font-size: 11px; }
    .news-item .column-left {
      width: 35%; }
    .news-item .column-right {
      width: 65%; }
    .news-item .news-img {
      max-width: 350px; }
    .news-item .news-info p {
      margin-top: 10px; } }

@media (max-width: 768px) {
  .news-item {
    font-size: 10px;
    padding: 10px;
    margin-bottom: 0; }
    .news-item .column-left {
      width: 40%; }
    .news-item .column-right {
      width: 60%; }
    .news-item .news-img {
      height: 140px;
      max-width: 300px; }
    .news-item .news-info {
      height: 140px; }
      .news-item .news-info h2 {
        margin-bottom: 20px; }
      .news-item .news-info p {
        display: none; }
      .news-item .news-info .read-more {
        position: relative; } }

@media (max-width: 480px) {
  .news-item .column-left {
    width: 50%; }
  .news-item .column-right {
    width: 50%; } }

/***********************************
** Banner
***********************************/
.banner-wrapper {
  position: relative;
  display: block; }
  .banner-wrapper img {
    display: block; }
  .banner-wrapper .banner-title {
    position: absolute;
    top: 50%;
    left: 5%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%); }

.main-banner-wrapper {
  height: 150px; }
  .main-banner-wrapper .banner-bg {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 150px;
    line-height: 150px; }
  .main-banner-wrapper .banner-top-title {
    font-size: 2.5em;
    display: inline-block;
    width: 100%;
    padding: 0 15px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: 300;
    vertical-align: middle; }
    .main-banner-wrapper .banner-top-title span {
      font-weight: 900; }

@media (min-width: 768px) {
  .layout-3 .banner-wrapper.bg {
    font-size: 14px;
    height: 120px;
    background-position: 30% center; }
  .layout-2 .banner-wrapper.bg {
    font-size: 16px;
    height: 120px;
    background-position: 30% center; } }

@media (min-width: 992px) {
  .layout-3 .banner-wrapper.bg {
    font-size: 12px; }
  .layout-4 .banner-wrapper.bg {
    font-size: 10px;
    height: 80px;
    background-position: 30% center; }
  .layout-2 .banner-wrapper.bg {
    font-size: 12px;
    height: 100px; } }

@media (min-width: 1200px) {
  .layout-3 .banner-wrapper.bg {
    font-size: 10px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left; }
  .layout-2 .banner-wrapper.bg {
    font-size: 14px;
    height: 120px; } }

@media (min-width: 1450px) {
  .layout-4 .banner-wrapper.bg {
    font-size: 12px; }
  .layout-2 .banner-wrapper.bg {
    font-size: 16px;
    height: 150px; } }

@media (max-width: 1199px) {
  .banner-wrapper {
    font-size: 12px; } }

@media (max-width: 991px) {
  .layout-4 .banner-wrapper.bg {
    font-size: 12px;
    height: 120px;
    background-position: 30% center; } }

@media (max-width: 767px) {
  .layout-3 .banner-wrapper {
    font-size: 12px; }
    .layout-3 .banner-wrapper.bg {
      height: 120px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left; }
  .layout-2 .banner-wrapper.bg {
    font-size: 12px;
    height: 120px; }
  .casino-list-view .main-banner-wrapper {
    display: none; } }

@media (max-width: 600px) {
  .banner-wrapper {
    font-size: 10px; }
  .layout-3 .banner-wrapper.bg {
    font-size: 10px;
    height: 90px; }
  .layout-4 .banner-wrapper.bg {
    font-size: 10px;
    height: 120px; }
  .layout-2 .banner-wrapper.bg {
    font-size: 10px;
    height: 100px; } }

/***********************************
** Boxes
***********************************/
.col_rank {
  font-size: 0;
  width: 165px;
  padding-right: 15px; }

.icon-star {
  background: url("../images/icons/star-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  display: inline-block;
  height: 22px;
  width: 22px;
  margin: 3px; }

.icon-apple, .icon-android {
  background: url("../images/icons/apple-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  margin: 2px 3px 2px 0;
  height: 17px;
  width: 14px; }

.icon-android {
  background: url("../images/icons/android-icon.png"); }

.icon-yes, .icon-no {
  background: url("../images/icons/yes-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  margin: -2px 0px 2px 3px;
  height: 17px;
  width: 17px; }

.list-table .icon-yes, .list-table .icon-no {
  height: 15px;
  width: 15px; }

.icon-no {
  background-image: url("../images/icons/no-icon.png"); }

.col_icons {
  width: 50px;
  text-align: right; }

.features-list.wtp {
  width: 70%; }
  .features-list.wtp > div {
    vertical-align: top;
    margin-top: 10px; }
  .features-list.wtp .wtp-box {
    display: inline-block;
    vertical-align: top;
    width: 80%;
    padding-left: 15px; }
  .features-list.wtp a {
    text-decoration: underline; }

.features-list > div {
  font-size: 0.9375em;
  font-weight: 400;
  text-align: left; }
  .features-list > div > span {
    font-weight: 700;
    color: #ff7500; }

.table-box-wrapper .table {
  display: table;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c5bfbf; }
  .table-box-wrapper .table .cell {
    display: table-cell;
    vertical-align: middle;
    border-top: none;
    padding: 0 3px;
    text-align: center;
    font-weight: inherit; }
  .table-box-wrapper .table img {
    vertical-align: middle; }
  .table-box-wrapper .table .col_payout {
    font-size: 1em;
    font-weight: 400;
    text-align: right;
    padding-right: 20px; }
    .table-box-wrapper .table .col_payout strong {
      color: #ff7500; }

.table-box-wrapper .col-img img {
  max-width: 80px; }

.table-box-wrapper .col_button {
  text-align: right;
  width: 130px;
  font-size: 1.25em; }
  .table-box-wrapper .col_button .btn {
    padding: 3px 0px;
    margin: 3px 0; }

.table-box-wrapper .col_img {
  min-width: 70px;
  text-align: left;
  width: 10%; }

.table-box-wrapper .col_lp {
  width: 3%; }

.table-box-wrapper .col_title {
  text-align: left !important;
  padding-left: 1%;
  padding-right: 1%;
  font-size: 1.25em; }

.table-box-wrapper .btn-table {
  width: 100%; }

.table-box-wrapper .table-inside {
  display: table; }
  .table-box-wrapper .table-inside > * {
    display: table-cell;
    vertical-align: middle;
    padding: 0 5px; }

.table-box-wrapper.list-table .col-img img {
  max-width: 115px; }

.table-box-wrapper.list-table .col_rank {
  display: block;
  padding-top: 5px; }

.table-box-wrapper.list-table .table-inside > * {
  display: block; }

.table-box-wrapper.list-table .col_lp {
  font-size: 1.25em; }

.table-box-wrapper.list-table .col_title {
  font-size: 1.625em; }

.table-box-wrapper.list-table .col_button {
  width: 150px; }

.table-box-wrapper.list-table .col_features {
  width: 150px; }
  .table-box-wrapper.list-table .col_features.xl {
    width: 200px; }

.table-box-wrapper.list-table.slot .col_features.column-1 {
  width: 135px; }

.table-box-wrapper.list-table.slot .col_features.column-2 {
  width: 175px; }

.casino-wrapper {
  margin-top: -120px; }
  .casino-wrapper .casino-img {
    height: 240px; }
    .casino-wrapper .casino-img img {
      width: 100%;
      display: block;
      height: 240px; }
  .casino-wrapper .casino-info {
    padding: 25px 0;
    position: relative; }
    .casino-wrapper .casino-info.slot .btn-table {
      top: 0; }
    .casino-wrapper .casino-info.slot .bottom {
      top: auto;
      position: relative;
      margin-top: 60px; }
    .casino-wrapper .casino-info .features-button {
      position: absolute;
      right: 0;
      top: 0;
      width: 28%; }
      .casino-wrapper .casino-info .features-button .btn {
        width: 100%;
        right: 0;
        padding-left: 0;
        padding-right: 0;
        position: relative; }
      .casino-wrapper .casino-info .features-button a {
        display: block; }
        .casino-wrapper .casino-info .features-button a img {
          width: 100%; }
    .casino-wrapper .casino-info .row {
      margin-left: 0;
      margin-right: 0; }
    .casino-wrapper .casino-info .bottom {
      position: absolute;
      top: 120px;
      width: 100%; }
    .casino-wrapper .casino-info .title {
      color: #fff;
      font-size: 2em;
      display: inline-block;
      vertical-align: middle; }
    .casino-wrapper .casino-info .col_rank {
      margin-left: 1.5em;
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px; }
  .casino-wrapper .features-list {
    display: inline-block;
    vertical-align: top;
    font-size: 1.125em;
    padding-right: 15px; }
  .casino-wrapper .btn-table {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    padding: 8px 75px;
    font-size: 2em;
    position: absolute;
    right: 10px; }

.top5-rank {
  font-size: 14px; }
  .top5-rank .table {
    margin-bottom: 5px; }
  .top5-rank .cell {
    vertical-align: middle;
    padding: 4px; }
  .top5-rank .col_lp {
    width: 10px; }
  .top5-rank .col_title {
    font-weight: 600; }
  .top5-rank .col_img {
    width: 26%; }
    .top5-rank .col_img a {
      display: block; }
      .top5-rank .col_img a img {
        display: block; }
  .top5-rank .col_button {
    width: 85px; }
    .top5-rank .col_button .btn {
      padding: 5px 12px;
      margin-bottom: 3px; }

@media (min-width: 768px) {
  .icon-star.number {
    display: none; } }

@media (max-width: 1679px) {
  .table-box-wrapper {
    font-size: 14px; }
    .table-box-wrapper.list-table {
      font-size: 16px; }
    .table-box-wrapper .col_button {
      width: 110px; }
  .icon-star {
    height: 18px;
    width: 18px; }
  .col_rank {
    width: 140px;
    padding-right: 10px; } }

@media (max-width: 1449px) {
  .table-box-wrapper {
    font-size: 11px; }
    .table-box-wrapper .col_button {
      width: 90px; }
  .icon-star {
    height: 16px;
    width: 16px; }
  .col_rank {
    font-size: 0;
    width: 130px;
    padding-right: 10px; }
  .casino-wrapper .casino-img {
    height: 210px; }
    .casino-wrapper .casino-img img {
      height: 210px; }
  .features-list.wtp {
    width: 70%; } }

@media (max-width: 1199px) {
  .table-box-wrapper {
    font-size: 16px; }
    .table-box-wrapper .col_button {
      width: 130px; }
  .icon-star {
    height: 22px;
    width: 22px; }
  .col_rank {
    width: 155px;
    padding-right: 10px; }
  .casino-wrapper .casino-img {
    height: 230px; }
    .casino-wrapper .casino-img img {
      height: 230px; }
  .casino-wrapper .casino-info {
    padding: 35px 0; }
    .casino-wrapper .casino-info.slot .features-button {
      width: 30%; }
  .features-list.wtp {
    width: 75%; }
    .features-list.wtp .wtp-box {
      margin-top: 10px;
      width: 100%;
      padding-left: 0; }
  .features-list.dev {
    width: 100%; }
  .top5-rank {
    font-size: 20px; }
    .top5-rank .col_img {
      width: 150px; }
    .top5-rank .col_button {
      width: 250px; }
      .top5-rank .col_button .btn {
        padding: 5px 12px;
        margin-bottom: 10px; }
    .top5-rank .col_title {
      padding-left: 25px; } }

@media (max-width: 991px) {
  .table-box-wrapper {
    font-size: 12px; }
    .table-box-wrapper.list-table {
      font-size: 14px; }
      .table-box-wrapper.list-table .col_features {
        width: 120px; }
      .table-box-wrapper.list-table .col_title {
        font-size: 1.25em; }
      .table-box-wrapper.list-table .col_button {
        width: 125px; }
      .table-box-wrapper.list-table.slot .col_features.column-1 {
        width: 125px; }
      .table-box-wrapper.list-table.slot .col_features.column-2 {
        width: 170px; }
  .col_button {
    width: 110px; }
  .icon-star {
    height: 18px;
    width: 18px; }
  .col_rank {
    padding-right: 0px; }
  .casino-wrapper {
    font-size: 14px; }
    .casino-wrapper .casino-img {
      height: 200px; }
      .casino-wrapper .casino-img img {
        height: 200px; }
    .casino-wrapper .casino-info {
      padding: 20px 0; }
      .casino-wrapper .casino-info .btn {
        padding: 8px 40px; } }

@media (max-width: 767px) {
  .page-template-template-rank-item {
    padding-bottom: 68px; }
  .table-box-wrapper {
    font-size: 14px; }
    .table-box-wrapper .table .col_rev {
      display: none; }
    .table-box-wrapper.list-table .col_title {
      font-size: 1.25em; }
    .table-box-wrapper.list-table .col_button {
      width: 130px; }
    .table-box-wrapper.list-table .col_img {
      width: 14%; }
    .table-box-wrapper.list-table .col_features {
      display: none; }
    .table-box-wrapper.list-table .col_rank {
      width: 130px; }
    .table-box-wrapper.list-table .icon-star {
      display: inline-block; }
      .table-box-wrapper.list-table .icon-star.number {
        display: none; }
    .table-box-wrapper.list-table .col_rev {
      display: block; }
    .table-box-wrapper .col_button {
      width: 110px; }
  .icon-star {
    display: none; }
    .icon-star.number {
      display: inline-block;
      font-size: 16px;
      width: 32px;
      height: 20px;
      background-size: 18px 18px;
      background-position: right;
      color: orange;
      text-align: left;
      font-weight: 900; }
  .col_rank {
    width: 50px; }
  .casino-wrapper {
    font-size: 14px;
    margin-top: 20px; }
    .casino-wrapper .casino-info.slot .bottom {
      margin-top: 15px; }
    .casino-wrapper .casino-grid.col-xs-4 {
      width: 35%;
      min-width: 250px; }
    .casino-wrapper .casino-grid.col-xs-8 {
      width: 55%; }
    .casino-wrapper .casino-img img {
      height: auto; }
    .casino-wrapper .casino-info {
      padding: 20px 0; }
      .casino-wrapper .casino-info .title {
        margin-bottom: 5px;
        display: block;
        color: #000; }
      .casino-wrapper .casino-info .bottom {
        margin-top: 20px;
        position: static; }
      .casino-wrapper .casino-info .features-button .btn {
        position: fixed !important;
        width: 100% !important;
        left: 0;
        top: auto;
        bottom: 0;
        z-index: 10;
        font-size: 2.875em;
        font-weight: 300; }
      .casino-wrapper .casino-info .col_rank {
        width: 130px;
        display: block;
        margin-left: 0; }
      .casino-wrapper .casino-info .icon-star {
        display: inline-block; }
        .casino-wrapper .casino-info .icon-star.number {
          display: none; }
    .casino-wrapper .features-list.wtp {
      width: 100%; }
  .top5-rank {
    font-size: 16px; }
    .top5-rank .col_img {
      width: 120px; }
    .top5-rank .col_button {
      width: 200px; }
      .top5-rank .col_button .btn {
        padding: 5px 12px;
        margin-bottom: 10px; } }

@media (max-width: 600px) {
  .table-box-wrapper {
    font-size: 12px; }
    .table-box-wrapper.list-table {
      font-size: 12px; }
  .casino-wrapper {
    font-size: 12px; }
    .casino-wrapper .casino-grid.col-xs-4 {
      width: 24%;
      min-width: 190px; }
    .casino-wrapper .casino-info {
      padding: 0; }
  .top5-rank {
    font-size: 16px; }
    .top5-rank .col_img {
      width: 105px; }
    .top5-rank .col_button {
      width: 140px; }
      .top5-rank .col_button .btn {
        padding: 5px 12px;
        margin-bottom: 5px; }
    .top5-rank .col_title {
      padding-left: 5px; } }

@media (max-width: 480px) {
  .table-box-wrapper {
    font-size: 11px; }
    .table-box-wrapper.list-table {
      font-size: 11px; }
      .table-box-wrapper.list-table .col_button {
        width: 70px; }
      .table-box-wrapper.list-table .col_rank {
        width: auto; }
    .table-box-wrapper .col_button {
      width: 70px; }
    .table-box-wrapper .col_rev {
      display: none; }
    .table-box-wrapper .table .col_payout {
      display: none; }
    .table-box-wrapper .table .icon-star {
      display: none; }
      .table-box-wrapper .table .icon-star.number {
        display: block; }
    .table-box-wrapper .table .table-inside span {
      display: none; }
  .casino-wrapper {
    font-size: 14px;
    text-align: center; }
    .casino-wrapper .casino-img {
      height: auto; }
    .casino-wrapper .casino-grid {
      width: 100% !important; }
    .casino-wrapper .casino-info {
      height: auto;
      padding: 0; }
      .casino-wrapper .casino-info .title {
        margin-top: 15px; }
      .casino-wrapper .casino-info .col_rank {
        display: inline-block; }
      .casino-wrapper .casino-info .bottom {
        margin-top: 0; }
      .casino-wrapper .casino-info .features-list {
        padding-right: 0;
        width: 100%; }
        .casino-wrapper .casino-info .features-list > div {
          text-align: center; }
        .casino-wrapper .casino-info .features-list:first-child {
          padding: 8px; }
  .top5-rank {
    font-size: 14px; }
    .top5-rank .col_img {
      width: 70px; }
    .top5-rank .col_button {
      width: 25%; }
      .top5-rank .col_button .btn {
        padding: 5px 12px;
        margin-bottom: 3px; } }

/***********************************
** Carousel
***********************************/
.swiper-button-next {
  background-image: url("../images/icons/arrow-right-icon.png"); }

.swiper-button-prev {
  background-image: url("../images/icons/arrow-left-icon.png"); }

.main-carousel .swiper-slide .carousel-bg {
  height: 370px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative; }

.main-carousel .swiper-slide .carousel-overlay {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%; }

.main-carousel .swiper-slide .carousel-info {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15%;
  padding: 0 40px;
  line-height: 3.5em;
  text-transform: uppercase;
  color: #fff; }
  .main-carousel .swiper-slide .carousel-info .carousel-line1 {
    font-size: 3.75em;
    font-weight: 300;
    display: block; }
  .main-carousel .swiper-slide .carousel-info .carousel-line2 {
    font-size: 3em;
    font-weight: 900;
    display: block; }

.news-carousel, .articles-carousel {
  border-bottom: 1px solid #c5bfbf; }
  .news-carousel .swiper-slide .list-box, .articles-carousel .swiper-slide .list-box {
    display: block; }
    .news-carousel .swiper-slide .list-box .list-image, .articles-carousel .swiper-slide .list-box .list-image {
      position: relative; }
      .news-carousel .swiper-slide .list-box .list-image img, .articles-carousel .swiper-slide .list-box .list-image img {
        display: block; }
    .news-carousel .swiper-slide .list-box .title-3, .articles-carousel .swiper-slide .list-box .title-3 {
      margin-top: 10px; }
    .news-carousel .swiper-slide .list-box:hover, .news-carousel .swiper-slide .list-box:focus, .articles-carousel .swiper-slide .list-box:hover, .articles-carousel .swiper-slide .list-box:focus {
      color: inherit; }
  .news-carousel .swiper-button-next, .news-carousel .swiper-button-prev, .articles-carousel .swiper-button-next, .articles-carousel .swiper-button-prev {
    display: none; }

@media (min-width: 992px) {
  .main-carousel .swiper-button-next {
    right: 40px; }
  .main-carousel .swiper-button-prev {
    left: 40px; } }

@media (max-width: 991px) {
  .main-carousel .swiper-slide .carousel-bg {
    height: 250px; }
  .main-carousel .swiper-slide .carousel-info {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center; }
    .main-carousel .swiper-slide .carousel-info .carousel-line1 {
      font-size: 3em; }
    .main-carousel .swiper-slide .carousel-info .carousel-line2 {
      font-size: 2.25em; } }

@media (max-width: 600px) {
  .main-carousel .swiper-slide .carousel-info .carousel-line1 {
    font-size: 2.5em; }
  .main-carousel .swiper-slide .carousel-info .carousel-line2 {
    font-size: 1.75em; } }

@media (max-width: 480px) {
  .news-carousel .swiper-slide .list-box .list-image:after, .articles-carousel .swiper-slide .list-box .list-image:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); }
  .news-carousel .swiper-button-next, .news-carousel .swiper-button-prev, .articles-carousel .swiper-button-next, .articles-carousel .swiper-button-prev {
    display: block;
    top: 40%; } }

/***********************************
** Footer
***********************************/
.tooltipster-wrapper {
  position: absolute;
  width: 220px;
  padding: 10px;
  bottom: calc(100% + 10px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  text-align: center;
  background: #222;
  color: #fff;
  opacity: 0;
  border: 2px solid orange;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px; }
  .tooltipster-wrapper:before, .tooltipster-wrapper:after {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -12px;
    width: 8px;
    height: 10px;
    z-index: 99;
    background: transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid orange;
    border-right: 8px solid transparent; }
  .tooltipster-wrapper:after {
    bottom: -10px;
    border-top: 8px solid #222; }

.pre-footer {
  text-align: center;
  border-top: 1px solid #ff7500;
  border-bottom: 1px solid #ff7500;
  background: #fff; }
  .pre-footer .inline-logo {
    line-height: 100px;
    display: inline-block;
    padding: 15px;
    text-align: left;
    font-size: 12px; }
    .pre-footer .inline-logo a {
      line-height: 100px;
      display: inline-block;
      vertical-align: middle;
      position: relative; }
      .pre-footer .inline-logo a:hover .tooltipster-wrapper {
        opacity: 1; }
      .pre-footer .inline-logo a p {
        line-height: normal;
        max-width: 200px;
        text-align: center; }

.footer-wrapper {
  text-align: center;
  background: #e5e5e5; }
  .footer-wrapper .footer-nav {
    font-size: 17px; }
    .footer-wrapper .footer-nav ul {
      list-style: none;
      padding: 20px 0 15px;
      display: inline-block;
      border-bottom: 1px solid #c5bfbf; }
      .footer-wrapper .footer-nav ul li {
        display: inline; }
        .footer-wrapper .footer-nav ul li > a {
          padding: 0 18px; }
      .footer-wrapper .footer-nav ul:before, .footer-wrapper .footer-nav ul:after {
        display: table;
        content: " "; }
  .footer-wrapper .copyright-wrapper {
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 20px; }

@media (max-width: 991px) {
  .pre-footer .inline-logo {
    display: block;
    padding: 20px 15px;
    text-align: center;
    line-height: normal; }
    .pre-footer .inline-logo a {
      line-height: normal; }
  .footer-wrapper .footer-nav {
    font-size: 14px; }
    .footer-wrapper .footer-nav ul li {
      display: block;
      margin-bottom: 10px; } }

/***********************************
** Header
***********************************/
.header-wrapper .container {
  padding-left: 15px;
  padding-right: 15px; }

.header-wrapper .navbar {
  background: white;
  margin-bottom: 0; }
  .header-wrapper .navbar .navbar-brand {
    float: none; }
  .header-wrapper .navbar .navbar-toggle {
    border: none;
    background: transparent;
    margin-top: 15px;
    margin-bottom: 18px;
    margin-right: 7px; }
    .header-wrapper .navbar .navbar-toggle .icon-bar {
      display: block;
      width: 45px;
      height: 4px;
      border-radius: 0px;
      margin-top: 10px;
      background: #ff7500; }
    .header-wrapper .navbar .navbar-toggle:hover, .header-wrapper .navbar .navbar-toggle .open {
      background: transparent; }
  .header-wrapper .navbar .navbar-nav > .active > a {
    background: none;
    color: inherit;
    color: black; }
  .header-wrapper .navbar .navbar-nav > li {
    font-weight: 900;
    text-transform: uppercase;
    margin: 0 25px; }
    .header-wrapper .navbar .navbar-nav > li > a {
      color: black;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .header-wrapper .navbar .navbar-nav > li > a:hover {
        color: #ff7500; }
    .header-wrapper .navbar .navbar-nav > li.active > a {
      color: #ff7500; }
  .header-wrapper .navbar .navbar-nav.language-switcher > li {
    margin: 0;
    font-weight: 400; }
    .header-wrapper .navbar .navbar-nav.language-switcher > li.open > a {
      color: #ff7500; }
    .header-wrapper .navbar .navbar-nav.language-switcher > li:hover > a {
      color: #ff7500; }
      .header-wrapper .navbar .navbar-nav.language-switcher > li:hover > a:after {
        content: none; }
    .header-wrapper .navbar .navbar-nav.language-switcher > li a {
      background: transparent;
      padding-left: 5px;
      padding-right: 5px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .header-wrapper .navbar .navbar-nav.language-switcher > li a img {
        padding-right: 5px;
        padding-bottom: 2px;
        vertical-align: middle; }
    .header-wrapper .navbar .navbar-nav.language-switcher > li ul {
      background: #fff; }
      .header-wrapper .navbar .navbar-nav.language-switcher > li ul > li:hover > a {
        color: #ff7500; }
    .header-wrapper .navbar .navbar-nav.language-switcher > li .dropdown-menu {
      position: absolute;
      float: left;
      margin: 2px 0 0;
      min-width: auto;
      top: auto;
      border: 1px solid rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      bottom: -20px; }
      .header-wrapper .navbar .navbar-nav.language-switcher > li .dropdown-menu li a {
        padding: 3px 10px; }

@media (min-width: 992px) {
  .header-wrapper .navbar {
    border-bottom: 2px solid #ff7500; }
    .header-wrapper .navbar .navbar-collapse {
      float: left;
      margin-left: 80px; }
    .header-wrapper .navbar .navbar-nav > li > a {
      font-size: 17px;
      color: black;
      padding: 60px 10px; }
      .header-wrapper .navbar .navbar-nav > li > a:after {
        content: "";
        position: absolute;
        left: -1px;
        bottom: -2px;
        width: 0;
        height: 5px;
        background: #ff7500;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .header-wrapper .navbar .navbar-nav > li:hover > a:after, .header-wrapper .navbar .navbar-nav > li.active > a:after {
      width: 100%; } }

@media (min-width: 1450px) {
  .header-wrapper .container {
    width: 1440px; } }

@media (min-width: 1680px) {
  .header-wrapper .container {
    width: 1670px; } }

@media (max-width: 1679px) {
  .header-wrapper .navbar .navbar-nav > li {
    margin: 0 15px; }
    .header-wrapper .navbar .navbar-nav > li > a {
      font-size: 15px; } }

@media (max-width: 1449px) {
  .header-wrapper .navbar .navbar-brand {
    margin-top: 5px; }
    .header-wrapper .navbar .navbar-brand img {
      max-width: 150px;
      display: block; }
  .header-wrapper .navbar .navbar-collapse {
    margin-left: 20px; }
  .header-wrapper .navbar .navbar-nav > li {
    margin: 0 10px; }
    .header-wrapper .navbar .navbar-nav > li > a {
      padding: 42px 10px;
      font-size: 13px; }
  .header-wrapper .navbar .navbar-nav.language-switcher > li .dropdown-menu {
    bottom: -40px; } }

@media (max-width: 1199px) {
  .header-wrapper .navbar .navbar-collapse {
    margin-left: 5px; }
  .header-wrapper .navbar .navbar-nav > li {
    margin: 0 5px; }
    .header-wrapper .navbar .navbar-nav > li > a {
      padding: 42px 5px;
      font-size: 12px; } }

@media (max-width: 991px) {
  .navbar-header {
    float: none; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-nav {
    margin: 7.5px -15px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .navbar-text {
    float: none;
    margin: 15px 0; }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important; }
  .collapsing {
    overflow: hidden !important; }
  .header-wrapper .container {
    position: relative; }
  .header-wrapper .navbar .navbar-collapse {
    border-top: none;
    margin-left: 5px;
    text-align: center; }
  .header-wrapper .navbar .navbar-brand {
    float: left; }
  .header-wrapper .navbar .navbar-nav {
    float: none; }
    .header-wrapper .navbar .navbar-nav > li {
      margin: 0 5px; }
      .header-wrapper .navbar .navbar-nav > li > a {
        padding: 12px 5px;
        font-size: 14px; }
    .header-wrapper .navbar .navbar-nav.language-switcher {
      position: absolute;
      top: 20px;
      right: 100px; }
      .header-wrapper .navbar .navbar-nav.language-switcher > li .dropdown-menu {
        bottom: -70px; } }

@media (max-width: 600px) {
  .header-wrapper .navbar .navbar-nav.language-switcher {
    right: 85px; } }

/***********************************
** Sidebar
***********************************/
.sidebar {
  padding: 10px 0;
  max-width: 768px;
  margin: 0 auto; }
  .sidebar .banners {
    text-align: center;
    margin-top: 40px; }
    .sidebar .banners .banner-box {
      overflow: hidden;
      width: 300px;
      text-align: center;
      margin: 0 auto;
      display: inline-block;
      vertical-align: baseline; }
  .sidebar .section-title {
    text-transform: inherit;
    font-size: 1em;
    font-weight: 700; }
    .sidebar .section-title .title-text span {
      font-weight: 700; }

.related-wrapper ul {
  padding-left: 20px;
  color: #ff7500;
  list-style-type: square; }
  .related-wrapper ul > li > a {
    cursor: pointer; }

@media (max-width: 1199px) {
  .sidebar .banners .banner-box {
    vertical-align: top; } }

@media (max-width: 991px) {
  .sidebar .section-title {
    font-size: 14px; }
  .related-wrapper {
    font-size: 17px; } }

.main-banner-wrapper .banner-bg.fixed {
  position: fixed;
  top: -100px;
  left: 0;
  height: 80px;
  width: 100%;
  z-index: 10; }
  .main-banner-wrapper .banner-bg.fixed.animated {
    -webkit-transition: top 0.25s ease;
    -moz-transition: top 0.25s ease;
    -ms-transition: top 0.25s ease;
    -o-transition: top 0.25s ease;
    transition: top 0.25s ease;
    top: 0px; }

.casino-wrapper .casino-info .top.fixed {
  position: fixed;
  left: 140px;
  top: -100px;
  z-index: 11; }
  .casino-wrapper .casino-info .top.fixed.animated {
    -webkit-transition: top 0.25s ease;
    -moz-transition: top 0.25s ease;
    -ms-transition: top 0.25s ease;
    -o-transition: top 0.25s ease;
    transition: top 0.25s ease;
    top: 21px; }

.casino-wrapper .casino-info .features-button.fixed {
  position: fixed;
  right: 45px;
  top: -100px;
  width: 200px;
  z-index: 11;
  display: block;
  height: 60px;
  overflow: hidden;
  -webkit-transition: top 0.25s ease;
  -moz-transition: top 0.25s ease;
  -ms-transition: top 0.25s ease;
  -o-transition: top 0.25s ease;
  transition: top 0.25s ease; }
  .casino-wrapper .casino-info .features-button.fixed.animated {
    top: 10px; }

.casino-wrapper .casino-img img.fixed {
  position: fixed;
  top: -100px;
  left: 45px;
  z-index: 11;
  width: auto;
  height: 60px;
  -webkit-transition: top 0.25s ease;
  -moz-transition: top 0.25s ease;
  -ms-transition: top 0.25s ease;
  -o-transition: top 0.25s ease;
  transition: top 0.25s ease; }
  .casino-wrapper .casino-img img.fixed.animated {
    top: 10px; }

#back-to-top {
  position: fixed;
  bottom: -100px;
  right: 45px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  background: #ce3939;
  color: #fff;
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  text-decoration: none;
  -webkit-transition: bottom 0.5s ease-out;
  -moz-transition: bottom 0.5s ease-out;
  -ms-transition: bottom 0.5s ease-out;
  -o-transition: bottom 0.5s ease-out;
  transition: bottom 0.5s ease-out; }

#back-to-top:hover {
  background: #a22b2b; }

#back-to-top.show {
  bottom: 45px; }

@media (max-width: 767px) {
  #back-to-top {
    right: 0; }
  #back-to-top.show {
    bottom: 0px; } }
