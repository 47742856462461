/***********************************
** Header
***********************************/
  .header-wrapper {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
    .navbar {
      background: white;
      margin-bottom: 0;
      .navbar-brand {
        float: none;
      }

      .navbar-toggle {
        border: none;
        background: transparent;
        margin-top: 15px;
        margin-bottom: 18px;
        margin-right: 7px;
        .icon-bar {
          display: block;
          width: 45px;
          height: 4px;
          border-radius: 0px;
          margin-top: 10px;
          background: $orange-main;
        }
        &:hover, .open {
          background: transparent;
        }
      }
      .navbar-nav {
        > .active {
          > a {
            background: none;
            color: inherit;
            color: black;
          }
        }
        > li {
          font-weight: 900;
          text-transform: uppercase;
          margin: 0 25px;
          > a {
            color: black;
            @include transition(all 0.3s ease);
            &:hover {
              color: $orange-main;
            }
          }
          &.active {
            > a {
              color: $orange-main;
            }
          }
        }
        &.language-switcher {
          > li {
            margin: 0;
            font-weight: 400;
            &.open {
              > a {
                color: $orange-main;
              }
            }
            &:hover {
              > a {
                color: $orange-main;
                &:after {
                  content: none;
                }
              }
            }
            a {
              background: transparent;
              padding-left: 5px;
              padding-right: 5px;
              @include transition(all 0.3s ease);
              img {
                padding-right: 5px;
                padding-bottom: 2px;
                vertical-align: middle;
              }
            }
            ul {
              background: #fff;
              > li {
                &:hover {
                  > a {
                    color: $orange-main;
                  }
                }
              }
            }
            .dropdown-menu {
              position: absolute;
              float: left;
              margin: 2px 0 0;
              min-width: auto;
              top: auto;
              border: 1px solid rgba(0,0,0,.15);
              -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
              box-shadow: 0 6px 12px rgba(0,0,0,.175);
              bottom: -20px;
              li {
                a {
                  padding: 3px 10px;
                }
              }
            }
          }
        }
      }
    }
  }
@media (min-width: 768px) {

}
@media (min-width: 992px) {
  .header-wrapper {
    .navbar {
      border-bottom: 2px solid $orange-main;
      .navbar-collapse {
        float: left;
        margin-left: 80px;
      }
      .navbar-nav {
        > li {
          > a {
            font-size: 17px;
            color: black;
            padding: 60px 10px;
            &:after {
              content: "";
              position: absolute;
              left: -1px;
              bottom: -2px;
              width: 0;
              height: 5px;
              background: $orange-main;
              @include transition(all 0.3s ease);
            }
          }
          &:hover, &.active {
            > a {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {

}

@media (min-width: 1450px) {
  .header-wrapper {
    .container {
      width: 1440px;
    }
  }
}

@media (min-width: 1680px) {
  .header-wrapper {
    .container {
      width: 1670px;
    }
  }
}

@media(max-width: 1679px) {
  .header-wrapper {
    .navbar {
      .navbar-nav {
        > li {
          margin: 0 15px;
          > a {
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media(max-width: 1449px) {
  .header-wrapper {
    .navbar {
      .navbar-brand {
        margin-top: 5px;
        img {
          max-width: 150px;
          display: block;
        }
      }
      .navbar-collapse {
        margin-left: 20px;
      }
      .navbar-nav {
        > li {
          margin: 0 10px;
          > a {
            padding: 42px 10px;
            font-size: 13px;
          }
        }
        &.language-switcher {
          > li {
            .dropdown-menu {
              bottom: -40px;
            }
          }
        }
      }
    }
  }
}
@media(max-width: 1199px) {
  .header-wrapper {
    .navbar {
      .navbar-collapse {
        margin-left: 5px;
      }
      .navbar-nav {
        > li {
          margin: 0 5px;
          > a {
            padding: 42px 5px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    /* since 3.1.0 */
    .navbar-collapse.collapse.in {
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }

    .header-wrapper {
      .container {
        position: relative;
      }

      .navbar {
        .navbar-collapse {
          border-top: none;
          margin-left: 5px;
          text-align: center;
        }
        .navbar-brand {
          float: left;
        }
        .navbar-nav {
          float: none;
          > li {
            margin: 0 5px;
            > a {
              padding: 12px 5px;
              font-size: 14px;
            }
          }
          &.language-switcher {
            position: absolute;
            top: 20px;
            right: 100px;
            > li {
              .dropdown-menu {
                bottom: -70px;
              }
            }
          }
        }
      }
    }
}

@media (max-width: 600px) {
  .header-wrapper {
    .navbar {
      .navbar-nav {
        &.language-switcher {
          right: 85px;
        }
      }
    }
  }
}
